<script>
import Offer from '../Offer'
import {handleWith} from "@/mixins/globalMixins";
export default {
	components: {
		Offer
	},
	name: 'ulgi',
	mixins: [handleWith],
}
</script>

<template>
	<Offer>
		<template #banner>
			<img src="./img/taxes.jpg" class="fixed-image" alt="banner">
		</template>
		<template #button>
			<i v-if="mobile === true" class="fas fa-arrow-left"></i>
			<router-link slot="button" to="/oferta">Wróć do spisu usług</router-link>
		</template>
		<template #small-title>
					<h4>PRAWO PODATKOWE</h4>
				</template>

				<template #title>
					<h2>Ulgi podatkowe</h2>
				</template>

				<template #text>
					<p>Doradzamy w zakresie dostępnych ulg podatkowych, które mogą znacząco obniżyć obciążenia finansowe działalności. Pomagamy klientom identyfikować oraz wykorzystywać ulgi i zwolnienia podatkowe. Przykładowe możliwe do wykorzystania ulgi to:
					</p>
				</template>
		<template #list>
					<ul class="offer__list">
						<li class="offer__list-item">
							<strong>Podwyższone 50% koszty uzyskania przychodów</strong><br><br>
							Ulga ta jest skierowana do szeroko pojętej działalności twórczej. Dzięki jej zastosowaniu de facto podatek jest płacony tylko od połowy przychodów.

						</li>
						<li class="offer__list-item">
							<strong>CIT estoński</strong><br><br>
							CIT estoński to nowoczesny model opodatkowania, który umożliwia odroczenie płatności podatku dochodowego do momentu wypłaty zysku z firmy (dywidenda(. Jest to korzystne rozwiązanie dla przedsiębiorstw reinwestujących zyski, pozwalające na zwiększenie płynności finansowej i usprawnienie zarządzania kapitałem. <br><br>
							Co istotne, przy wypłacie zysku, ten model opodatkowania pozwala obniżyć efektywne opodatkowanie niemal, aż o 10 pkt %. <br><br>
							CIT estoński pozwala obniżyć efektywne opodatkowanie przy wypłacie dywidendy prawie o 10%. W CIT estońskim można również w ogóle nie płacić podatku CIT, zakładając brak wypłaty zysku ze spółki. Rozwiązanie to jest wybierane przez wiele przedsiębiorstw.


						</li>
						<li class="offer__list-item">
							<strong>Ulga IP Box</strong><br><br>
							Ulga IP Box pozwala na korzystniejsze opodatkowanie dochodów uzyskanych z praw własności intelektualnej, takich jak patenty, oprogramowanie czy wzory użytkowe. Przedsiębiorcy mogą skorzystać z preferencyjnej stawki podatku dochodowego wynoszącej 5% dla dochodów z kwalifikowanych praw IP.


						</li>
						<li class="offer__list-item">
							<strong>Ulga B+R</strong><br><br>Ulga na badania i rozwój (B+R) to rozwiązanie podatkowe, które umożliwia dodatkowe odliczenie od podstawy opodatkowania kosztów związanych z pracami badawczo-rozwojowymi. To atrakcyjna opcja dla firm inwestujących w innowacje i nowe technologie. Oferujemy wsparcie w przygotowaniu niezbędnej dokumentacji i weryfikacji kosztów kwalifikowanych, aby przedsiębiorcy mogli w pełni wykorzystać potencjał ulgi B+R.
						</li>
						<li class="offer__list-item">
							<strong>Ulga na Powrót
							</strong><br><br>
							Ulga na powrót to preferencyjne rozwiązanie dla osób, które zdecydowały się na powrót do Polski po dłuższym okresie pracy za granicą (minimum trzy lata kalendarzowe). Dzięki niej mogą przez przez cztery kolejno po sobie następujące lata korzystać z częściowego zwolnienia z podatku dochodowego - ulga polega na zwolnieniu od podatku dochodowego przychodów, maksymalnie <br><strong>do kwoty 85 528 zł.</strong>


						</li>
					</ul>
				</template>
	</Offer>
</template>

<style scoped lang="scss">
.fixed-image {
	object-fit: cover;
	object-position: 50% 28%;
}
</style>
